import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v("Elimina Task")]),_c(VCardText,[_c('p',[_vm._v("Sei sicuro di voler eliminare il task \""+_vm._s(_vm.task.title)+"\"?")])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Annulla")]),_c(VBtn,{attrs:{"color":"success darken-1","text":""},on:{"click":_vm.deleteTask}},[_vm._v("Elimina")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }