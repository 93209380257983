<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100 justify-content-center text-center">
        <div class="clearfix">
          <img src="img/logo_abyway.png">
          <h1 class="display-3 mr-4">404</h1>
          <h4 class="pt-3">Oops! Forse ti sei perso!</h4>
          <p class="text-muted">La pagina che cercavi non è stata trovata.</p>
        </div>
      </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Page404'
}
</script>
