<template id="st">
  <iframe
    id="stats_frame"
    :src="urlIframe"
    
    class="frame-stats"
    width="100%"
    frameborder="0"
    :height="windowHeight"
  >
  </iframe>
  <!-- </div> -->
</template>
<script>

export default {
  name: "Statistiche",
  data: function () {
    return {
      windowHeight: 0,
      userID: localStorage.getItem("userID"),
      userEnergyID: localStorage.getItem("idUtenteEnergy"),
      urlIframe:"",
    };
  },
  methods: {
    setIframe(){
      if (this.$attrs.portale == "broker"){
        this.urlIframe= this.$custom_json.broker_statistiche + this.userID;
      }
      if (this.$attrs.portale == "energy"){
        this.urlIframe= this.$custom_json.energy_statistiche + this.userEnergyID;
      }
      
    },

    // eslint-disable-next-line no-unused-vars
    getWindowHeight(event) {
      var head = document.getElementById("header").offsetHeight - 5;
      var foot = document.getElementById("footer").offsetHeight;
      var space = window.innerHeight;
      var spazio = space - (head + foot);
      document
        .getElementById("stats_frame")
        .setAttribute("style", "height:" + spazio + "px !important;");
      this.windowHeight = spazio;
    },
  },
  mounted() {
    this.setIframe();
    this.$nextTick(function () {
      window.addEventListener("resize", this.getWindowHeight);
      this.getWindowHeight();
      document.documentElement.style.overflow = "hidden";
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowHeight);
    document.documentElement.style.overflow = "auto";
  },
};
</script>
<style scoped>
.row-stats {
  padding-left: 0;
  padding-right: 0;

  min-height: 100%;
  height: auto !important; /* cross-browser */
  height: 100%; /* cross-browser */
}

</style>