import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[(!_vm.editing)?_c('span',[_vm._v("New Card")]):_c('span',[_vm._v("Edit Card")]),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c(VTextField,{attrs:{"label":"Title","required":"","rules":[function (v) { return !!v || 'Title is required'; }]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c(VTextarea,{attrs:{"label":"Description"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.saveCard}},[(!_vm.editing)?_c('span',[_vm._v("Create")]):_c('span',[_vm._v("Save")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }