import { render, staticRenderFns } from "./ValidaDoc.vue?vue&type=template&id=7a0bd17c&scoped=true"
import script from "./ValidaDoc.vue?vue&type=script&lang=js"
export * from "./ValidaDoc.vue?vue&type=script&lang=js"
import style0 from "./ValidaDoc.vue?vue&type=style&index=0&id=7a0bd17c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a0bd17c",
  null
  
)

export default component.exports