<template>
  <CFooter>

    <div class="mfs-auto">
      <span class="mr-1" target="_blank"><em>Powered by Navert S.r.l.</em></span>
      
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
