import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('CButton',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"danger","variant":"ghost"}},'CButton',attrs,false),on),[_c('i',{staticClass:"fas fa-user-slash"}),_vm._v(" Rifiuta ")])]}}]),model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c(VCard,{staticClass:"text-center"},[_c(VCardText,[_c(VContainer,[_c('div',{staticClass:"text-h5",staticStyle:{"color":"darkred"}},[_vm._v(" Conferma di voler eliminare "),_c('br'),_vm._v(" "+_vm._s(_vm.candidato.candidato)+" ")]),_c('section',[_c('div',{staticClass:"container pb-0"},[_c('div',{staticClass:"pb-3 font-weight-bold h5"},[_vm._v(" Scrivi la motivazione per cui vuoi eliminare il candidato ")]),_c(VTextarea,{attrs:{"rows":"4","outlined":""},model:{value:(_vm.notaDel),callback:function ($$v) {_vm.notaDel=$$v},expression:"notaDel"}})],1),_c(VDivider,{staticClass:"mt-0"}),_c(VRow,{attrs:{"align-content":"center"}},[_c(VCheckbox,{staticStyle:{"font-weight":"600","color":"darkred !important"},attrs:{"color":"red darken-3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"color":"darkred !important"}},[_vm._v("Confermando elimini il contato dall'elenco")])]},proxy:true}]),model:{value:(_vm.confermaDel),callback:function ($$v) {_vm.confermaDel=$$v},expression:"confermaDel"}})],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('small',[_vm._v(" Eliminato in data e ora"),_c('br'),_c('strong',[_vm._v(_vm._s(new Date().toLocaleString("it-IT")))])])]),_c(VCol,{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('small',[_vm._v(" Operatore"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.user.Nominativo))])])])],1)],1)])],1),_c(VCardActions,[_c(VSpacer),_c('CButton',{staticClass:"mx-2",attrs:{"color":"danger","variant":"ghost"},on:{"click":function($event){_vm.dialog2 = false;
          _vm.resetModaleConferme();}}},[_c('i',{staticClass:"fas fa-times"}),_vm._v("  Annulla ")]),_c(VBtn,{class:{ disabled_input: _vm.isSaveButtonDisabled },attrs:{"color":"#1f4b6b","dark":""},on:{"click":function($event){(_vm.dialog2 = false), _vm.EliminaContatto()}}},[_c('i',{staticClass:"far fa-trash-alt"}),_vm._v("  Elimina ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }