import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTooltip,{attrs:{"bottom":"","color":"#1f4b6b"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","fab":"","x-small":""},on:{"click":_vm.openNoteModal}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-edit fa-2x"})])]}}])},[_c('span',[_vm._v("Visualizza Note")])]),_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.noteMode),callback:function ($$v) {_vm.noteMode=$$v},expression:"noteMode"}},[_c(VCard,[_c(VCardTitle),_c(VCardText,[_c('span',{staticClass:"text-h5"},[_vm._v("Aggiungi una nota per "+_vm._s(_vm.candidato.candidato))]),_c(VContainer,[_c(VTextarea,{attrs:{"rows":"4","outlined":""},model:{value:(_vm.nota),callback:function ($$v) {_vm.nota=$$v},expression:"nota"}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"blue-grey","dark":""},on:{"click":function($event){_vm.noteMode = !_vm.noteMode;
                _vm.nota = '';}}},[_c('i',{staticClass:"fas fa-times"}),_vm._v("  chiudi ")]),_c(VBtn,{class:{ disabled_input: _vm.isSaveButtonDisabled },attrs:{"align-end":"","color":"#1f4b6b","dark":""},on:{"click":_vm.saveNote}},[_c('i',{staticClass:"far fa-save"}),_vm._v("   Salva ")])],1)],1),_c(VDivider),_c('span',{staticClass:"text-h5"},[_vm._v("Storico delle note inserite:")]),_c(VTimeline,{attrs:{"align-top":"","dense":""}},_vm._l((_vm.noteData),function(item){return _c(VTimelineItem,{key:item.id_note,attrs:{"color":_vm.getItemColor(item),"small":""}},[_c(VRow,{staticClass:"pt-1"},[_c(VCol,{attrs:{"cols":"3"}},[_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(item.data_ins)))])]),_c(VCol,[_c('strong',[_vm._v(_vm._s(item.fase))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.testo)+" "),_c('br'),_c('cite',{staticStyle:{"font-size":"0.6rem","color":"#1f4b6b !important"}},[_vm._v(_vm._s(item.id_user_name))])])])],1)],1)}),1)],1),_c(VCardActions,[_c(VSpacer)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }