import { render, staticRenderFns } from "./Statistiche.vue?vue&type=template&id=160b19c8&scoped=true"
import script from "./Statistiche.vue?vue&type=script&lang=js"
export * from "./Statistiche.vue?vue&type=script&lang=js"
import style0 from "./Statistiche.vue?vue&type=style&index=0&id=160b19c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160b19c8",
  null
  
)

export default component.exports