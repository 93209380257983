import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"kanban-add-card",on:{"click":_vm.showForm}},[_c(VCardText,{staticClass:"kanban-add-card-text"},[_c(VIcon,[_vm._v("mdi-plus-circle")]),_vm._v(" Aggiungi un nuovo task ")],1),(_vm.showForm)?_c(VCardActions,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();return _vm.addTask.apply(null, arguments)}}},[_c(VTextField,{attrs:{"label":"Titolo","required":"","outlined":""},model:{value:(_vm.newTaskTitle),callback:function ($$v) {_vm.newTaskTitle=$$v},expression:"newTaskTitle"}}),_c(VBtn,{attrs:{"type":"submit"}},[_vm._v("Aggiungi")]),_c(VBtn,{on:{"click":_vm.hideForm}},[_vm._v("Annulla")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }