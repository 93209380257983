import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Aggiungi una nuova lista ")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Titolo","outlined":"","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":_vm.cancel}},[_vm._v("Annulla")]),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.save}},[_vm._v("Salva")])],1)],1)],1),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Aggiungi lista ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }