import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTooltip,{attrs:{"bottom":"","color":"#1f4b6b"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"warning","fab":"","x-small":""},on:{"click":_vm.openLogModal}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-alarm")])],1)]}}])},[_c('span',[_vm._v("Cronologia")])]),_c(VDialog,{attrs:{"width":"600px"},model:{value:(_vm.logMode),callback:function ($$v) {_vm.logMode=$$v},expression:"logMode"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Cronologia delle operazioni sul candidato"),_c('br'),_vm._v(" "+_vm._s(_vm.candidato.candidato))])]),_c(VCardText,[_c(VTimeline,{attrs:{"align-top":"","dense":""}},_vm._l((_vm.logData),function(item){return _c(VTimelineItem,{key:item.id_log,attrs:{"color":_vm.getItemColor(item),"small":""}},[_c(VRow,{staticClass:"pt-1"},[_c(VCol,{attrs:{"cols":"3"}},[_c('strong',[_vm._v(_vm._s(_vm._f("formatDate")(item.data)))])]),_c(VCol,[_c('strong',[_vm._v(_vm._s(item.action))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.desc)+" "),_c('br'),_c('cite',{staticStyle:{"font-size":"0.6rem","color":"#1f4b6b !important"}},[_vm._v(_vm._s(item.nome_user))])])])],1)],1)}),1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","color":"blue-grey","dark":""},on:{"click":function($event){_vm.logMode = !_vm.logMode}}},[_c('i',{staticClass:"fas fa-times"}),_vm._v("  chiudi ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }